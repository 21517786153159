body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satoshi:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	@apply leading-[normal] m-0;
}
*,
::before,
::after {
	border-width: 0;
}

@keyframes bounce-in-top {
	0% {
		transform: translateY(-500px);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	38% {
		transform: translateY(0);
		animation-timing-function: ease-out;
		opacity: 1;
	}
	55% {
		transform: translateY(-65px);
		animation-timing-function: ease-in;
	}
	72%,
	90%,
	100% {
		transform: translateY(0);
		animation-timing-function: ease-out;
	}
	81% {
		transform: translateY(-28px);
		animation-timing-function: ease-in;
	}
	95% {
		transform: translateY(-8px);
		animation-timing-function: ease-in;
	}
}
