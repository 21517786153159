/*!
 * Tabler Icons 1.41.1 by tabler - https://tabler.io
 * License - https://github.com/tabler/tabler-icons/blob/master/LICENSE
 */
@font-face {
  font-family: tabler-icons;
  font-style: normal;
  font-weight: 400;
  src: url(tabler/tabler-icons.eot);
  src: url(tabler/tabler-iconsd41d.eot?#iefix) format('embedded-opentype'), url(tabler/tabler-icons.woff2) format('woff2'),
    url(tabler/tabler-icons.woff) format('woff'), url(tabler/tabler-icons.ttf) format('truetype'),
    url(tabler/tabler-icons.svg#tabler-icons) format('svg');
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: tabler-icons;
    src: url(tabler/tabler-icons.svg#tabler-icons) format('svg');
  }
}
.ti {
  font-family: tabler-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ti-2fa:before {
  content: '\eca0';
}
.ti-3d-cube-sphere:before {
  content: '\ecd7';
}
.ti-a-b:before {
  content: '\ec36';
}
.ti-access-point:before {
  content: '\ed1b';
}
.ti-access-point-off:before {
  content: '\ed1a';
}
.ti-accessible:before {
  content: '\eba9';
}
.ti-activity:before {
  content: '\ed23';
}
.ti-ad:before {
  content: '\ea02';
}
.ti-ad-2:before {
  content: '\ef1f';
}
.ti-adjustments:before {
  content: '\ea03';
}
.ti-adjustments-alt:before {
  content: '\ec37';
}
.ti-adjustments-horizontal:before {
  content: '\ec38';
}
.ti-aerial-lift:before {
  content: '\edfe';
}
.ti-affiliate:before {
  content: '\edff';
}
.ti-alarm:before {
  content: '\ea04';
}
.ti-alert-circle:before {
  content: '\ea05';
}
.ti-alert-octagon:before {
  content: '\ecc6';
}
.ti-alert-triangle:before {
  content: '\ea06';
}
.ti-alien:before {
  content: '\ebde';
}
.ti-align-center:before {
  content: '\ea07';
}
.ti-align-justified:before {
  content: '\ea08';
}
.ti-align-left:before {
  content: '\ea09';
}
.ti-align-right:before {
  content: '\ea0a';
}
.ti-ambulance:before {
  content: '\ebf5';
}
.ti-anchor:before {
  content: '\eb76';
}
.ti-angle:before {
  content: '\ef20';
}
.ti-antenna-bars-1:before {
  content: '\ecc7';
}
.ti-antenna-bars-2:before {
  content: '\ecc8';
}
.ti-antenna-bars-3:before {
  content: '\ecc9';
}
.ti-antenna-bars-4:before {
  content: '\ecca';
}
.ti-antenna-bars-5:before {
  content: '\eccb';
}
.ti-aperture:before {
  content: '\eb58';
}
.ti-apple:before {
  content: '\ef21';
}
.ti-apps:before {
  content: '\ebb6';
}
.ti-archive:before {
  content: '\ea0b';
}
.ti-arrow-back:before {
  content: '\ea0c';
}
.ti-arrow-back-up:before {
  content: '\eb77';
}
.ti-arrow-bar-down:before {
  content: '\ea0d';
}
.ti-arrow-bar-left:before {
  content: '\ea0e';
}
.ti-arrow-bar-right:before {
  content: '\ea0f';
}
.ti-arrow-bar-to-down:before {
  content: '\ec88';
}
.ti-arrow-bar-to-left:before {
  content: '\ec89';
}
.ti-arrow-bar-to-right:before {
  content: '\ec8a';
}
.ti-arrow-bar-to-up:before {
  content: '\ec8b';
}
.ti-arrow-bar-up:before {
  content: '\ea10';
}
.ti-arrow-big-down:before {
  content: '\edda';
}
.ti-arrow-big-left:before {
  content: '\eddb';
}
.ti-arrow-big-right:before {
  content: '\eddc';
}
.ti-arrow-big-top:before {
  content: '\eddd';
}
.ti-arrow-bottom-bar:before {
  content: '\ed98';
}
.ti-arrow-bottom-circle:before {
  content: '\ed99';
}
.ti-arrow-bottom-square:before {
  content: '\ed9a';
}
.ti-arrow-bottom-tail:before {
  content: '\ed9b';
}
.ti-arrow-down:before {
  content: '\ea16';
}
.ti-arrow-down-circle:before {
  content: '\ea11';
}
.ti-arrow-down-left:before {
  content: '\ea13';
}
.ti-arrow-down-left-circle:before {
  content: '\ea12';
}
.ti-arrow-down-right:before {
  content: '\ea15';
}
.ti-arrow-down-right-circle:before {
  content: '\ea14';
}
.ti-arrow-forward:before {
  content: '\ea17';
}
.ti-arrow-forward-up:before {
  content: '\eb78';
}
.ti-arrow-left:before {
  content: '\ea19';
}
.ti-arrow-left-bar:before {
  content: '\ed9c';
}
.ti-arrow-left-circle:before {
  content: '\ea18';
}
.ti-arrow-left-square:before {
  content: '\ed9d';
}
.ti-arrow-left-tail:before {
  content: '\ed9e';
}
.ti-arrow-loop-left:before {
  content: '\ed9f';
}
.ti-arrow-loop-right:before {
  content: '\eda0';
}
.ti-arrow-narrow-down:before {
  content: '\ea1a';
}
.ti-arrow-narrow-left:before {
  content: '\ea1b';
}
.ti-arrow-narrow-right:before {
  content: '\ea1c';
}
.ti-arrow-narrow-up:before {
  content: '\ea1d';
}
.ti-arrow-ramp-left:before {
  content: '\ed3c';
}
.ti-arrow-ramp-right:before {
  content: '\ed3d';
}
.ti-arrow-right:before {
  content: '\ea1f';
}
.ti-arrow-right-bar:before {
  content: '\eda1';
}
.ti-arrow-right-circle:before {
  content: '\ea1e';
}
.ti-arrow-right-square:before {
  content: '\eda2';
}
.ti-arrow-right-tail:before {
  content: '\eda3';
}
.ti-arrow-top-bar:before {
  content: '\eda4';
}
.ti-arrow-top-circle:before {
  content: '\eda5';
}
.ti-arrow-top-square:before {
  content: '\eda6';
}
.ti-arrow-top-tail:before {
  content: '\eda7';
}
.ti-arrow-up:before {
  content: '\ea25';
}
.ti-arrow-up-circle:before {
  content: '\ea20';
}
.ti-arrow-up-left:before {
  content: '\ea22';
}
.ti-arrow-up-left-circle:before {
  content: '\ea21';
}
.ti-arrow-up-right:before {
  content: '\ea24';
}
.ti-arrow-up-right-circle:before {
  content: '\ea23';
}
.ti-arrow-wave-left-down:before {
  content: '\eda8';
}
.ti-arrow-wave-left-up:before {
  content: '\eda9';
}
.ti-arrow-wave-right-down:before {
  content: '\edaa';
}
.ti-arrow-wave-right-up:before {
  content: '\edab';
}
.ti-arrows-diagonal:before {
  content: '\ea27';
}
.ti-arrows-diagonal-2:before {
  content: '\ea26';
}
.ti-arrows-double-ne-sw:before {
  content: '\edde';
}
.ti-arrows-double-nw-se:before {
  content: '\eddf';
}
.ti-arrows-double-se-nw:before {
  content: '\ede0';
}
.ti-arrows-double-sw-ne:before {
  content: '\ede1';
}
.ti-arrows-down:before {
  content: '\edad';
}
.ti-arrows-down-up:before {
  content: '\edac';
}
.ti-arrows-horizontal:before {
  content: '\eb59';
}
.ti-arrows-join:before {
  content: '\edaf';
}
.ti-arrows-join-2:before {
  content: '\edae';
}
.ti-arrows-left:before {
  content: '\edb1';
}
.ti-arrows-left-down:before {
  content: '\ee00';
}
.ti-arrows-left-right:before {
  content: '\edb0';
}
.ti-arrows-maximize:before {
  content: '\ea28';
}
.ti-arrows-minimize:before {
  content: '\ea29';
}
.ti-arrows-right:before {
  content: '\edb3';
}
.ti-arrows-right-down:before {
  content: '\ee01';
}
.ti-arrows-right-left:before {
  content: '\edb2';
}
.ti-arrows-sort:before {
  content: '\eb5a';
}
.ti-arrows-split:before {
  content: '\edb5';
}
.ti-arrows-split-2:before {
  content: '\edb4';
}
.ti-arrows-up:before {
  content: '\edb7';
}
.ti-arrows-up-down:before {
  content: '\edb6';
}
.ti-arrows-up-left:before {
  content: '\ee02';
}
.ti-arrows-up-right:before {
  content: '\ee03';
}
.ti-arrows-vertical:before {
  content: '\eb5b';
}
.ti-artboard:before {
  content: '\ea2a';
}
.ti-aspect-ratio:before {
  content: '\ed30';
}
.ti-at:before {
  content: '\ea2b';
}
.ti-atom:before {
  content: '\eb79';
}
.ti-atom-2:before {
  content: '\ebdf';
}
.ti-award:before {
  content: '\ea2c';
}
.ti-backhoe:before {
  content: '\ed86';
}
.ti-backspace:before {
  content: '\ea2d';
}
.ti-ball-american-football:before {
  content: '\ee04';
}
.ti-ball-basketball:before {
  content: '\ec28';
}
.ti-ball-bowling:before {
  content: '\ec29';
}
.ti-ball-football:before {
  content: '\ee06';
}
.ti-ball-football-off:before {
  content: '\ee05';
}
.ti-ball-tennis:before {
  content: '\ec2a';
}
.ti-ball-volleyball:before {
  content: '\ec2b';
}
.ti-ban:before {
  content: '\ea2e';
}
.ti-bandage:before {
  content: '\eb7a';
}
.ti-barcode:before {
  content: '\ebc6';
}
.ti-basket:before {
  content: '\ebe1';
}
.ti-battery:before {
  content: '\ea34';
}
.ti-battery-1:before {
  content: '\ea2f';
}
.ti-battery-2:before {
  content: '\ea30';
}
.ti-battery-3:before {
  content: '\ea31';
}
.ti-battery-4:before {
  content: '\ea32';
}
.ti-battery-automotive:before {
  content: '\ee07';
}
.ti-battery-charging:before {
  content: '\ea33';
}
.ti-battery-off:before {
  content: '\ed1c';
}
.ti-bed:before {
  content: '\eb5c';
}
.ti-bell:before {
  content: '\ea35';
}
.ti-bell-minus:before {
  content: '\ede2';
}
.ti-bell-off:before {
  content: '\ece9';
}
.ti-bell-plus:before {
  content: '\ede3';
}
.ti-bell-ringing:before {
  content: '\ed07';
}
.ti-bell-ringing-2:before {
  content: '\ede4';
}
.ti-bell-x:before {
  content: '\ede5';
}
.ti-bike:before {
  content: '\ea36';
}
.ti-binary:before {
  content: '\ee08';
}
.ti-biohazard:before {
  content: '\ecb8';
}
.ti-blockquote:before {
  content: '\ee09';
}
.ti-bluetooth:before {
  content: '\ea37';
}
.ti-bluetooth-connected:before {
  content: '\ecea';
}
.ti-bluetooth-off:before {
  content: '\eceb';
}
.ti-bold:before {
  content: '\eb7b';
}
.ti-bolt:before {
  content: '\ea38';
}
.ti-bolt-off:before {
  content: '\ecec';
}
.ti-bone:before {
  content: '\edb8';
}
.ti-book:before {
  content: '\ea39';
}
.ti-bookmark:before {
  content: '\ea3a';
}
.ti-bookmark-off:before {
  content: '\eced';
}
.ti-bookmarks:before {
  content: '\ed08';
}
.ti-border-all:before {
  content: '\ea3b';
}
.ti-border-bottom:before {
  content: '\ea3c';
}
.ti-border-horizontal:before {
  content: '\ea3d';
}
.ti-border-inner:before {
  content: '\ea3e';
}
.ti-border-left:before {
  content: '\ea3f';
}
.ti-border-none:before {
  content: '\ea40';
}
.ti-border-outer:before {
  content: '\ea41';
}
.ti-border-radius:before {
  content: '\eb7c';
}
.ti-border-right:before {
  content: '\ea42';
}
.ti-border-style:before {
  content: '\ee0a';
}
.ti-border-style-2:before {
  content: '\ef22';
}
.ti-border-top:before {
  content: '\ea43';
}
.ti-border-vertical:before {
  content: '\ea44';
}
.ti-bottle:before {
  content: '\ef0b';
}
.ti-box:before {
  content: '\ea45';
}
.ti-box-margin:before {
  content: '\ee0b';
}
.ti-box-model:before {
  content: '\ee0c';
}
.ti-box-model-2:before {
  content: '\ef23';
}
.ti-box-multiple:before {
  content: '\ee17';
}
.ti-box-multiple-0:before {
  content: '\ee0d';
}
.ti-box-multiple-1:before {
  content: '\ee0e';
}
.ti-box-multiple-2:before {
  content: '\ee0f';
}
.ti-box-multiple-3:before {
  content: '\ee10';
}
.ti-box-multiple-4:before {
  content: '\ee11';
}
.ti-box-multiple-5:before {
  content: '\ee12';
}
.ti-box-multiple-6:before {
  content: '\ee13';
}
.ti-box-multiple-7:before {
  content: '\ee14';
}
.ti-box-multiple-8:before {
  content: '\ee15';
}
.ti-box-multiple-9:before {
  content: '\ee16';
}
.ti-box-padding:before {
  content: '\ee18';
}
.ti-braces:before {
  content: '\ebcc';
}
.ti-brackets:before {
  content: '\ebcd';
}
.ti-brand-airbnb:before {
  content: '\ed68';
}
.ti-brand-android:before {
  content: '\ec16';
}
.ti-brand-apple:before {
  content: '\ec17';
}
.ti-brand-apple-arcade:before {
  content: '\ed69';
}
.ti-brand-appstore:before {
  content: '\ed24';
}
.ti-brand-asana:before {
  content: '\edc5';
}
.ti-brand-behance:before {
  content: '\ec6e';
}
.ti-brand-bing:before {
  content: '\edc6';
}
.ti-brand-bitbucket:before {
  content: '\edc7';
}
.ti-brand-booking:before {
  content: '\edc8';
}
.ti-brand-chrome:before {
  content: '\ec18';
}
.ti-brand-codepen:before {
  content: '\ec6f';
}
.ti-brand-codesandbox:before {
  content: '\ed6a';
}
.ti-brand-css3:before {
  content: '\ed6b';
}
.ti-brand-deviantart:before {
  content: '\ecfb';
}
.ti-brand-discord:before {
  content: '\ece3';
}
.ti-brand-disqus:before {
  content: '\edc9';
}
.ti-brand-docker:before {
  content: '\edca';
}
.ti-brand-dribbble:before {
  content: '\ec19';
}
.ti-brand-edge:before {
  content: '\ecfc';
}
.ti-brand-facebook:before {
  content: '\ec1a';
}
.ti-brand-figma:before {
  content: '\ec93';
}
.ti-brand-firefox:before {
  content: '\ecfd';
}
.ti-brand-flickr:before {
  content: '\ecfe';
}
.ti-brand-foursquare:before {
  content: '\ecff';
}
.ti-brand-framer:before {
  content: '\ec1b';
}
.ti-brand-github:before {
  content: '\ec1c';
}
.ti-brand-gitlab:before {
  content: '\ec1d';
}
.ti-brand-google:before {
  content: '\ec1f';
}
.ti-brand-google-analytics:before {
  content: '\edcb';
}
.ti-brand-google-drive:before {
  content: '\ec1e';
}
.ti-brand-google-play:before {
  content: '\ed25';
}
.ti-brand-gravatar:before {
  content: '\edcc';
}
.ti-brand-hipchat:before {
  content: '\edcd';
}
.ti-brand-html5:before {
  content: '\ed6c';
}
.ti-brand-instagram:before {
  content: '\ec20';
}
.ti-brand-javascript:before {
  content: '\ef0c';
}
.ti-brand-kickstarter:before {
  content: '\edce';
}
.ti-brand-kotlin:before {
  content: '\ed6d';
}
.ti-brand-linkedin:before {
  content: '\ec8c';
}
.ti-brand-medium:before {
  content: '\ec70';
}
.ti-brand-messenger:before {
  content: '\ec71';
}
.ti-brand-netflix:before {
  content: '\edcf';
}
.ti-brand-open-source:before {
  content: '\edd0';
}
.ti-brand-opera:before {
  content: '\ec21';
}
.ti-brand-pagekit:before {
  content: '\edd1';
}
.ti-brand-patreon:before {
  content: '\edd2';
}
.ti-brand-paypal:before {
  content: '\ec22';
}
.ti-brand-pinterest:before {
  content: '\ec8d';
}
.ti-brand-pocket:before {
  content: '\ed00';
}
.ti-brand-producthunt:before {
  content: '\edd3';
}
.ti-brand-python:before {
  content: '\ed01';
}
.ti-brand-reddit:before {
  content: '\ec8e';
}
.ti-brand-safari:before {
  content: '\ec23';
}
.ti-brand-sass:before {
  content: '\edd4';
}
.ti-brand-sentry:before {
  content: '\edd5';
}
.ti-brand-shazam:before {
  content: '\edd6';
}
.ti-brand-sketch:before {
  content: '\ec24';
}
.ti-brand-skype:before {
  content: '\ed02';
}
.ti-brand-slack:before {
  content: '\ec72';
}
.ti-brand-snapchat:before {
  content: '\ec25';
}
.ti-brand-soundcloud:before {
  content: '\ed6e';
}
.ti-brand-spotify:before {
  content: '\ed03';
}
.ti-brand-steam:before {
  content: '\ed6f';
}
.ti-brand-stripe:before {
  content: '\edd7';
}
.ti-brand-tabler:before {
  content: '\ec8f';
}
.ti-brand-tailwind:before {
  content: '\eca1';
}
.ti-brand-telegram:before {
  content: '\ec26';
}
.ti-brand-tidal:before {
  content: '\ed70';
}
.ti-brand-tiktok:before {
  content: '\ec73';
}
.ti-brand-tinder:before {
  content: '\ed71';
}
.ti-brand-tumblr:before {
  content: '\ed04';
}
.ti-brand-twitch:before {
  content: '\ed05';
}
.ti-brand-twitter:before {
  content: '\ec27';
}
.ti-brand-unsplash:before {
  content: '\edd8';
}
.ti-brand-vercel:before {
  content: '\ef24';
}
.ti-brand-vimeo:before {
  content: '\ed06';
}
.ti-brand-vk:before {
  content: '\ed72';
}
.ti-brand-whatsapp:before {
  content: '\ec74';
}
.ti-brand-windows:before {
  content: '\ecd8';
}
.ti-brand-yahoo:before {
  content: '\ed73';
}
.ti-brand-ycombinator:before {
  content: '\edd9';
}
.ti-brand-youtube:before {
  content: '\ec90';
}
.ti-briefcase:before {
  content: '\ea46';
}
.ti-brightness:before {
  content: '\eb7f';
}
.ti-brightness-2:before {
  content: '\ee19';
}
.ti-brightness-down:before {
  content: '\eb7d';
}
.ti-brightness-half:before {
  content: '\ee1a';
}
.ti-brightness-up:before {
  content: '\eb7e';
}
.ti-browser:before {
  content: '\ebb7';
}
.ti-brush:before {
  content: '\ebb8';
}
.ti-bucket:before {
  content: '\ea47';
}
.ti-bug:before {
  content: '\ea48';
}
.ti-building:before {
  content: '\ea4f';
}
.ti-building-arch:before {
  content: '\ea49';
}
.ti-building-bank:before {
  content: '\ebe2';
}
.ti-building-bridge:before {
  content: '\ea4b';
}
.ti-building-bridge-2:before {
  content: '\ea4a';
}
.ti-building-carousel:before {
  content: '\ed87';
}
.ti-building-castle:before {
  content: '\ed88';
}
.ti-building-church:before {
  content: '\ea4c';
}
.ti-building-community:before {
  content: '\ebf6';
}
.ti-building-cottage:before {
  content: '\ee1b';
}
.ti-building-factory:before {
  content: '\ee1c';
}
.ti-building-fortress:before {
  content: '\ed89';
}
.ti-building-hospital:before {
  content: '\ea4d';
}
.ti-building-lighthouse:before {
  content: '\ed8a';
}
.ti-building-monument:before {
  content: '\ed26';
}
.ti-building-pavilon:before {
  content: '\ebf7';
}
.ti-building-skyscraper:before {
  content: '\ec39';
}
.ti-building-store:before {
  content: '\ea4e';
}
.ti-building-warehouse:before {
  content: '\ebe3';
}
.ti-bulb:before {
  content: '\ea51';
}
.ti-bulb-off:before {
  content: '\ea50';
}
.ti-bulldozer:before {
  content: '\ee1d';
}
.ti-bus:before {
  content: '\ebe4';
}
.ti-businessplan:before {
  content: '\ee1e';
}
.ti-calculator:before {
  content: '\eb80';
}
.ti-calendar:before {
  content: '\ea53';
}
.ti-calendar-event:before {
  content: '\ea52';
}
.ti-calendar-minus:before {
  content: '\ebb9';
}
.ti-calendar-off:before {
  content: '\ee1f';
}
.ti-calendar-plus:before {
  content: '\ebba';
}
.ti-calendar-stats:before {
  content: '\ee20';
}
.ti-calendar-time:before {
  content: '\ee21';
}
.ti-camera:before {
  content: '\ea54';
}
.ti-camera-minus:before {
  content: '\ec3a';
}
.ti-camera-off:before {
  content: '\ecee';
}
.ti-camera-plus:before {
  content: '\ec3b';
}
.ti-camera-rotate:before {
  content: '\ee22';
}
.ti-camera-selfie:before {
  content: '\ee23';
}
.ti-candy:before {
  content: '\ef0d';
}
.ti-capture:before {
  content: '\ec3c';
}
.ti-car:before {
  content: '\ebbb';
}
.ti-car-crane:before {
  content: '\ef25';
}
.ti-caravan:before {
  content: '\ec7c';
}
.ti-cardboards:before {
  content: '\ed74';
}
.ti-caret-down:before {
  content: '\eb5d';
}
.ti-caret-left:before {
  content: '\eb5e';
}
.ti-caret-right:before {
  content: '\eb5f';
}
.ti-caret-up:before {
  content: '\eb60';
}
.ti-cash:before {
  content: '\ea55';
}
.ti-cash-banknote:before {
  content: '\ee25';
}
.ti-cash-banknote-off:before {
  content: '\ee24';
}
.ti-cast:before {
  content: '\ea56';
}
.ti-ce:before {
  content: '\ed75';
}
.ti-certificate:before {
  content: '\ed76';
}
.ti-charging-pile:before {
  content: '\ee26';
}
.ti-chart-arcs:before {
  content: '\ee28';
}
.ti-chart-arcs-3:before {
  content: '\ee27';
}
.ti-chart-area:before {
  content: '\ea58';
}
.ti-chart-area-line:before {
  content: '\ea57';
}
.ti-chart-arrows:before {
  content: '\ee2a';
}
.ti-chart-arrows-vertical:before {
  content: '\ee29';
}
.ti-chart-bar:before {
  content: '\ea59';
}
.ti-chart-bubble:before {
  content: '\ec75';
}
.ti-chart-candle:before {
  content: '\ea5a';
}
.ti-chart-circles:before {
  content: '\ee2b';
}
.ti-chart-donut:before {
  content: '\ea5b';
}
.ti-chart-donut-2:before {
  content: '\ee2c';
}
.ti-chart-donut-3:before {
  content: '\ee2d';
}
.ti-chart-donut-4:before {
  content: '\ee2e';
}
.ti-chart-dots:before {
  content: '\ee2f';
}
.ti-chart-infographic:before {
  content: '\ee30';
}
.ti-chart-line:before {
  content: '\ea5c';
}
.ti-chart-pie:before {
  content: '\ea5d';
}
.ti-chart-pie-2:before {
  content: '\ee31';
}
.ti-chart-pie-3:before {
  content: '\ee32';
}
.ti-chart-pie-4:before {
  content: '\ee33';
}
.ti-chart-radar:before {
  content: '\ed77';
}
.ti-check:before {
  content: '\ea5e';
}
.ti-checkbox:before {
  content: '\eba6';
}
.ti-checks:before {
  content: '\ebaa';
}
.ti-cheese:before {
  content: '\ef26';
}
.ti-chevron-down:before {
  content: '\ea5f';
}
.ti-chevron-down-left:before {
  content: '\ed09';
}
.ti-chevron-down-right:before {
  content: '\ed0a';
}
.ti-chevron-left:before {
  content: '\ea60';
}
.ti-chevron-right:before {
  content: '\ea61';
}
.ti-chevron-up:before {
  content: '\ea62';
}
.ti-chevron-up-left:before {
  content: '\ed0b';
}
.ti-chevron-up-right:before {
  content: '\ed0c';
}
.ti-chevrons-down:before {
  content: '\ea63';
}
.ti-chevrons-down-left:before {
  content: '\ed0d';
}
.ti-chevrons-down-right:before {
  content: '\ed0e';
}
.ti-chevrons-left:before {
  content: '\ea64';
}
.ti-chevrons-right:before {
  content: '\ea65';
}
.ti-chevrons-up:before {
  content: '\ea66';
}
.ti-chevrons-up-left:before {
  content: '\ed0f';
}
.ti-chevrons-up-right:before {
  content: '\ed10';
}
.ti-christmas-tree:before {
  content: '\ed78';
}
.ti-circle:before {
  content: '\ea6b';
}
.ti-circle-0:before {
  content: '\ee34';
}
.ti-circle-1:before {
  content: '\ee35';
}
.ti-circle-2:before {
  content: '\ee36';
}
.ti-circle-3:before {
  content: '\ee37';
}
.ti-circle-4:before {
  content: '\ee38';
}
.ti-circle-5:before {
  content: '\ee39';
}
.ti-circle-6:before {
  content: '\ee3a';
}
.ti-circle-7:before {
  content: '\ee3b';
}
.ti-circle-8:before {
  content: '\ee3c';
}
.ti-circle-9:before {
  content: '\ee3d';
}
.ti-circle-check:before {
  content: '\ea67';
}
.ti-circle-dashed:before {
  content: '\ed27';
}
.ti-circle-dotted:before {
  content: '\ed28';
}
.ti-circle-half:before {
  content: '\ee3f';
}
.ti-circle-half-vertical:before {
  content: '\ee3e';
}
.ti-circle-minus:before {
  content: '\ea68';
}
.ti-circle-off:before {
  content: '\ee40';
}
.ti-circle-plus:before {
  content: '\ea69';
}
.ti-circle-square:before {
  content: '\ece4';
}
.ti-circle-x:before {
  content: '\ea6a';
}
.ti-circles:before {
  content: '\ece5';
}
.ti-clear-all:before {
  content: '\ee41';
}
.ti-clear-formatting:before {
  content: '\ebe5';
}
.ti-click:before {
  content: '\ebbc';
}
.ti-clipboard:before {
  content: '\ea6f';
}
.ti-clipboard-check:before {
  content: '\ea6c';
}
.ti-clipboard-list:before {
  content: '\ea6d';
}
.ti-clipboard-x:before {
  content: '\ea6e';
}
.ti-clock:before {
  content: '\ea70';
}
.ti-cloud:before {
  content: '\ea76';
}
.ti-cloud-download:before {
  content: '\ea71';
}
.ti-cloud-fog:before {
  content: '\ecd9';
}
.ti-cloud-off:before {
  content: '\ed3e';
}
.ti-cloud-rain:before {
  content: '\ea72';
}
.ti-cloud-snow:before {
  content: '\ea73';
}
.ti-cloud-storm:before {
  content: '\ea74';
}
.ti-cloud-upload:before {
  content: '\ea75';
}
.ti-code:before {
  content: '\ea77';
}
.ti-code-minus:before {
  content: '\ee42';
}
.ti-code-plus:before {
  content: '\ee43';
}
.ti-coffee:before {
  content: '\ef0e';
}
.ti-coin:before {
  content: '\eb82';
}
.ti-color-picker:before {
  content: '\ebe6';
}
.ti-color-swatch:before {
  content: '\eb61';
}
.ti-column-insert-left:before {
  content: '\ee44';
}
.ti-column-insert-right:before {
  content: '\ee45';
}
.ti-columns:before {
  content: '\eb83';
}
.ti-comet:before {
  content: '\ec76';
}
.ti-command:before {
  content: '\ea78';
}
.ti-compass:before {
  content: '\ea79';
}
.ti-confetti:before {
  content: '\ee46';
}
.ti-container:before {
  content: '\ee47';
}
.ti-contrast:before {
  content: '\ec4e';
}
.ti-cookie:before {
  content: '\ef0f';
}
.ti-copy:before {
  content: '\ea7a';
}
.ti-copyleft:before {
  content: '\ec3d';
}
.ti-copyright:before {
  content: '\ea7b';
}
.ti-corner-down-left:before {
  content: '\ea7c';
}
.ti-corner-down-left-double:before {
  content: '\ee48';
}
.ti-corner-down-right:before {
  content: '\ea7d';
}
.ti-corner-down-right-double:before {
  content: '\ee49';
}
.ti-corner-left-down:before {
  content: '\ea7e';
}
.ti-corner-left-down-double:before {
  content: '\ee4a';
}
.ti-corner-left-up:before {
  content: '\ea7f';
}
.ti-corner-left-up-double:before {
  content: '\ee4b';
}
.ti-corner-right-down:before {
  content: '\ea80';
}
.ti-corner-right-down-double:before {
  content: '\ee4c';
}
.ti-corner-right-up:before {
  content: '\ea81';
}
.ti-corner-right-up-double:before {
  content: '\ee4d';
}
.ti-corner-up-left:before {
  content: '\ea82';
}
.ti-corner-up-left-double:before {
  content: '\ee4e';
}
.ti-corner-up-right:before {
  content: '\ea83';
}
.ti-corner-up-right-double:before {
  content: '\ee4f';
}
.ti-crane:before {
  content: '\ef27';
}
.ti-credit-card:before {
  content: '\ea84';
}
.ti-credit-card-off:before {
  content: '\ed11';
}
.ti-crop:before {
  content: '\ea85';
}
.ti-crosshair:before {
  content: '\ec3e';
}
.ti-crown:before {
  content: '\ed12';
}
.ti-crown-off:before {
  content: '\ee50';
}
.ti-cup:before {
  content: '\ef28';
}
.ti-curly-loop:before {
  content: '\ecda';
}
.ti-currency-bahraini:before {
  content: '\ee51';
}
.ti-currency-bath:before {
  content: '\ee52';
}
.ti-currency-bitcoin:before {
  content: '\ebab';
}
.ti-currency-cent:before {
  content: '\ee53';
}
.ti-currency-dinar:before {
  content: '\ee54';
}
.ti-currency-dirham:before {
  content: '\ee55';
}
.ti-currency-dollar:before {
  content: '\eb84';
}
.ti-currency-dollar-australian:before {
  content: '\ee56';
}
.ti-currency-dollar-canadian:before {
  content: '\ee57';
}
.ti-currency-dollar-singapore:before {
  content: '\ee58';
}
.ti-currency-ethereum:before {
  content: '\ee59';
}
.ti-currency-euro:before {
  content: '\eb85';
}
.ti-currency-forint:before {
  content: '\ee5a';
}
.ti-currency-frank:before {
  content: '\ee5b';
}
.ti-currency-krone-czech:before {
  content: '\ee5c';
}
.ti-currency-krone-danish:before {
  content: '\ee5d';
}
.ti-currency-krone-swedish:before {
  content: '\ee5e';
}
.ti-currency-leu:before {
  content: '\ee5f';
}
.ti-currency-lira:before {
  content: '\ee60';
}
.ti-currency-litecoin:before {
  content: '\ee61';
}
.ti-currency-naira:before {
  content: '\ee62';
}
.ti-currency-pound:before {
  content: '\ebac';
}
.ti-currency-real:before {
  content: '\ee63';
}
.ti-currency-renminbi:before {
  content: '\ee64';
}
.ti-currency-ripple:before {
  content: '\ee65';
}
.ti-currency-riyal:before {
  content: '\ee66';
}
.ti-currency-rubel:before {
  content: '\ee67';
}
.ti-currency-rupee:before {
  content: '\ebad';
}
.ti-currency-shekel:before {
  content: '\ee68';
}
.ti-currency-taka:before {
  content: '\ee69';
}
.ti-currency-tugrik:before {
  content: '\ee6a';
}
.ti-currency-won:before {
  content: '\ee6b';
}
.ti-currency-yen:before {
  content: '\ebae';
}
.ti-currency-zloty:before {
  content: '\ee6c';
}
.ti-current-location:before {
  content: '\ecef';
}
.ti-cursor-text:before {
  content: '\ee6d';
}
.ti-cut:before {
  content: '\ea86';
}
.ti-dashboard:before {
  content: '\ea87';
}
.ti-database:before {
  content: '\ea88';
}
.ti-database-export:before {
  content: '\ee6e';
}
.ti-database-import:before {
  content: '\ee6f';
}
.ti-database-off:before {
  content: '\ee70';
}
.ti-details:before {
  content: '\ee71';
}
.ti-device-analytics:before {
  content: '\ee72';
}
.ti-device-audio-tape:before {
  content: '\ee73';
}
.ti-device-cctv:before {
  content: '\ee74';
}
.ti-device-computer-camera:before {
  content: '\ee76';
}
.ti-device-computer-camera-off:before {
  content: '\ee75';
}
.ti-device-desktop:before {
  content: '\ea89';
}
.ti-device-desktop-analytics:before {
  content: '\ee77';
}
.ti-device-desktop-off:before {
  content: '\ee78';
}
.ti-device-floppy:before {
  content: '\eb62';
}
.ti-device-gamepad:before {
  content: '\eb63';
}
.ti-device-laptop:before {
  content: '\eb64';
}
.ti-device-mobile:before {
  content: '\ea8a';
}
.ti-device-mobile-message:before {
  content: '\ee79';
}
.ti-device-mobile-rotated:before {
  content: '\ecdb';
}
.ti-device-mobile-vibration:before {
  content: '\eb86';
}
.ti-device-speaker:before {
  content: '\ea8b';
}
.ti-device-tablet:before {
  content: '\ea8c';
}
.ti-device-tv:before {
  content: '\ea8d';
}
.ti-device-watch:before {
  content: '\ebf9';
}
.ti-devices:before {
  content: '\eb87';
}
.ti-devices-2:before {
  content: '\ed29';
}
.ti-devices-pc:before {
  content: '\ee7a';
}
.ti-diamond:before {
  content: '\eb65';
}
.ti-dice:before {
  content: '\eb66';
}
.ti-dimensions:before {
  content: '\ee7b';
}
.ti-direction:before {
  content: '\ebfb';
}
.ti-direction-horizontal:before {
  content: '\ebfa';
}
.ti-directions:before {
  content: '\ea8e';
}
.ti-disabled:before {
  content: '\ea8f';
}
.ti-disabled-2:before {
  content: '\ebaf';
}
.ti-disc:before {
  content: '\ea90';
}
.ti-discount:before {
  content: '\ebbd';
}
.ti-discount-2:before {
  content: '\ee7c';
}
.ti-divide:before {
  content: '\ed5c';
}
.ti-dna:before {
  content: '\ee7d';
}
.ti-dog-bowl:before {
  content: '\ef29';
}
.ti-dots:before {
  content: '\ea95';
}
.ti-dots-circle-horizontal:before {
  content: '\ea91';
}
.ti-dots-diagonal:before {
  content: '\ea93';
}
.ti-dots-diagonal-2:before {
  content: '\ea92';
}
.ti-dots-vertical:before {
  content: '\ea94';
}
.ti-download:before {
  content: '\ea96';
}
.ti-drag-drop:before {
  content: '\eb89';
}
.ti-drag-drop-2:before {
  content: '\eb88';
}
.ti-drone:before {
  content: '\ed79';
}
.ti-drone-off:before {
  content: '\ee7e';
}
.ti-droplet:before {
  content: '\ea97';
}
.ti-droplet-filled:before {
  content: '\ee80';
}
.ti-droplet-filled-2:before {
  content: '\ee7f';
}
.ti-droplet-half:before {
  content: '\ee82';
}
.ti-droplet-half-2:before {
  content: '\ee81';
}
.ti-droplet-off:before {
  content: '\ee83';
}
.ti-ear:before {
  content: '\ebce';
}
.ti-ear-off:before {
  content: '\ee84';
}
.ti-edit:before {
  content: '\ea98';
}
.ti-edit-circle:before {
  content: '\ee85';
}
.ti-egg:before {
  content: '\eb8a';
}
.ti-emphasis:before {
  content: '\ebcf';
}
.ti-equal:before {
  content: '\ee87';
}
.ti-equal-not:before {
  content: '\ee86';
}
.ti-eraser:before {
  content: '\eb8b';
}
.ti-exchange:before {
  content: '\ebe7';
}
.ti-exposure:before {
  content: '\eb8c';
}
.ti-external-link:before {
  content: '\ea99';
}
.ti-eye:before {
  content: '\ea9a';
}
.ti-eye-check:before {
  content: '\ee88';
}
.ti-eye-off:before {
  content: '\ecf0';
}
.ti-eyeglass:before {
  content: '\ee8a';
}
.ti-eyeglass-2:before {
  content: '\ee89';
}
.ti-face-id:before {
  content: '\ea9b';
}
.ti-fall:before {
  content: '\ecb9';
}
.ti-feather:before {
  content: '\ee8b';
}
.ti-fence:before {
  content: '\ef2a';
}
.ti-file:before {
  content: '\eaa4';
}
.ti-file-alert:before {
  content: '\ede6';
}
.ti-file-analytics:before {
  content: '\ede7';
}
.ti-file-certificate:before {
  content: '\ed4d';
}
.ti-file-check:before {
  content: '\ea9c';
}
.ti-file-code:before {
  content: '\ebd0';
}
.ti-file-code-2:before {
  content: '\ede8';
}
.ti-file-diff:before {
  content: '\ecf1';
}
.ti-file-dislike:before {
  content: '\ed2a';
}
.ti-file-download:before {
  content: '\ea9d';
}
.ti-file-export:before {
  content: '\ede9';
}
.ti-file-horizontal:before {
  content: '\ebb0';
}
.ti-file-import:before {
  content: '\edea';
}
.ti-file-info:before {
  content: '\edec';
}
.ti-file-invoice:before {
  content: '\eb67';
}
.ti-file-like:before {
  content: '\ed2b';
}
.ti-file-minus:before {
  content: '\ea9e';
}
.ti-file-music:before {
  content: '\ea9f';
}
.ti-file-off:before {
  content: '\ecf2';
}
.ti-file-phone:before {
  content: '\ecdc';
}
.ti-file-plus:before {
  content: '\eaa0';
}
.ti-file-report:before {
  content: '\eded';
}
.ti-file-search:before {
  content: '\ed5d';
}
.ti-file-shredder:before {
  content: '\eaa1';
}
.ti-file-symlink:before {
  content: '\ed53';
}
.ti-file-text:before {
  content: '\eaa2';
}
.ti-file-upload:before {
  content: '\ec91';
}
.ti-file-x:before {
  content: '\eaa3';
}
.ti-file-zip:before {
  content: '\ed4e';
}
.ti-files:before {
  content: '\edef';
}
.ti-files-off:before {
  content: '\edee';
}
.ti-filter:before {
  content: '\eaa5';
}
.ti-filter-off:before {
  content: '\ed2c';
}
.ti-fingerprint:before {
  content: '\ebd1';
}
.ti-firetruck:before {
  content: '\ebe8';
}
.ti-fish:before {
  content: '\ef2b';
}
.ti-flag:before {
  content: '\eaa6';
}
.ti-flag-2:before {
  content: '\ee8c';
}
.ti-flag-3:before {
  content: '\ee8d';
}
.ti-flame:before {
  content: '\ec2c';
}
.ti-flare:before {
  content: '\ee8e';
}
.ti-flask:before {
  content: '\ebd2';
}
.ti-flip-horizontal:before {
  content: '\eaa7';
}
.ti-flip-vertical:before {
  content: '\eaa8';
}
.ti-float-center:before {
  content: '\ebb1';
}
.ti-float-left:before {
  content: '\ebb2';
}
.ti-float-none:before {
  content: '\ed13';
}
.ti-float-right:before {
  content: '\ebb3';
}
.ti-focus:before {
  content: '\eb8d';
}
.ti-focus-2:before {
  content: '\ebd3';
}
.ti-fold:before {
  content: '\ed56';
}
.ti-fold-down:before {
  content: '\ed54';
}
.ti-fold-up:before {
  content: '\ed55';
}
.ti-folder:before {
  content: '\eaad';
}
.ti-folder-minus:before {
  content: '\eaaa';
}
.ti-folder-off:before {
  content: '\ed14';
}
.ti-folder-plus:before {
  content: '\eaab';
}
.ti-folder-x:before {
  content: '\eaac';
}
.ti-folders:before {
  content: '\eaae';
}
.ti-forbid:before {
  content: '\ebd5';
}
.ti-forbid-2:before {
  content: '\ebd4';
}
.ti-forklift:before {
  content: '\ebe9';
}
.ti-forms:before {
  content: '\ee8f';
}
.ti-frame:before {
  content: '\eaaf';
}
.ti-friends:before {
  content: '\eab0';
}
.ti-gas-station:before {
  content: '\ec7d';
}
.ti-gauge:before {
  content: '\eab1';
}
.ti-geometry:before {
  content: '\ee90';
}
.ti-ghost:before {
  content: '\eb8e';
}
.ti-gift:before {
  content: '\eb68';
}
.ti-git-branch:before {
  content: '\eab2';
}
.ti-git-commit:before {
  content: '\eab3';
}
.ti-git-compare:before {
  content: '\eab4';
}
.ti-git-fork:before {
  content: '\eb8f';
}
.ti-git-merge:before {
  content: '\eab5';
}
.ti-git-pull-request:before {
  content: '\eab6';
}
.ti-glass:before {
  content: '\eab8';
}
.ti-glass-full:before {
  content: '\eab7';
}
.ti-glass-off:before {
  content: '\ee91';
}
.ti-globe:before {
  content: '\eab9';
}
.ti-golf:before {
  content: '\ed8c';
}
.ti-gps:before {
  content: '\ed7a';
}
.ti-grain:before {
  content: '\ee92';
}
.ti-grid-dots:before {
  content: '\eaba';
}
.ti-grip-horizontal:before {
  content: '\ec00';
}
.ti-grip-vertical:before {
  content: '\ec01';
}
.ti-growth:before {
  content: '\ee93';
}
.ti-h-1:before {
  content: '\ec94';
}
.ti-h-2:before {
  content: '\ec95';
}
.ti-h-3:before {
  content: '\ec96';
}
.ti-h-4:before {
  content: '\ec97';
}
.ti-h-5:before {
  content: '\ec98';
}
.ti-h-6:before {
  content: '\ec99';
}
.ti-hand-finger:before {
  content: '\ee94';
}
.ti-hand-little-finger:before {
  content: '\ee95';
}
.ti-hand-middle-finger:before {
  content: '\ec2d';
}
.ti-hand-off:before {
  content: '\ed15';
}
.ti-hand-ring-finger:before {
  content: '\ee96';
}
.ti-hand-rock:before {
  content: '\ee97';
}
.ti-hand-stop:before {
  content: '\ec2e';
}
.ti-hand-three-fingers:before {
  content: '\ee98';
}
.ti-hand-two-fingers:before {
  content: '\ee99';
}
.ti-hanger:before {
  content: '\ee9a';
}
.ti-hash:before {
  content: '\eabc';
}
.ti-heading:before {
  content: '\ee9b';
}
.ti-headphones:before {
  content: '\eabd';
}
.ti-headphones-off:before {
  content: '\ed1d';
}
.ti-headset:before {
  content: '\eb90';
}
.ti-heart:before {
  content: '\eabe';
}
.ti-heart-broken:before {
  content: '\ecba';
}
.ti-helicopter:before {
  content: '\ed8e';
}
.ti-helicopter-landing:before {
  content: '\ed8d';
}
.ti-help:before {
  content: '\eabf';
}
.ti-hexagon:before {
  content: '\ec02';
}
.ti-hexagon-off:before {
  content: '\ee9c';
}
.ti-hierarchy:before {
  content: '\ee9e';
}
.ti-hierarchy-2:before {
  content: '\ee9d';
}
.ti-history:before {
  content: '\ebea';
}
.ti-home:before {
  content: '\eac1';
}
.ti-home-2:before {
  content: '\eac0';
}
.ti-ice-cream:before {
  content: '\eac2';
}
.ti-ice-cream-2:before {
  content: '\ee9f';
}
.ti-id:before {
  content: '\eac3';
}
.ti-inbox:before {
  content: '\eac4';
}
.ti-indent-decrease:before {
  content: '\eb91';
}
.ti-indent-increase:before {
  content: '\eb92';
}
.ti-infinity:before {
  content: '\eb69';
}
.ti-info-circle:before {
  content: '\eac5';
}
.ti-info-square:before {
  content: '\eac6';
}
.ti-italic:before {
  content: '\eb93';
}
.ti-jump-rope:before {
  content: '\ed8f';
}
.ti-karate:before {
  content: '\ed32';
}
.ti-key:before {
  content: '\eac7';
}
.ti-keyboard:before {
  content: '\ebd6';
}
.ti-keyboard-hide:before {
  content: '\ec7e';
}
.ti-keyboard-off:before {
  content: '\eea0';
}
.ti-keyboard-show:before {
  content: '\ec7f';
}
.ti-language:before {
  content: '\ebbe';
}
.ti-layers-difference:before {
  content: '\eac8';
}
.ti-layers-intersect:before {
  content: '\eac9';
}
.ti-layers-linked:before {
  content: '\eea1';
}
.ti-layers-subtract:before {
  content: '\eaca';
}
.ti-layers-union:before {
  content: '\eacb';
}
.ti-layout:before {
  content: '\eadb';
}
.ti-layout-2:before {
  content: '\eacc';
}
.ti-layout-align-bottom:before {
  content: '\eacd';
}
.ti-layout-align-center:before {
  content: '\eace';
}
.ti-layout-align-left:before {
  content: '\eacf';
}
.ti-layout-align-middle:before {
  content: '\ead0';
}
.ti-layout-align-right:before {
  content: '\ead1';
}
.ti-layout-align-top:before {
  content: '\ead2';
}
.ti-layout-bottombar:before {
  content: '\ead3';
}
.ti-layout-cards:before {
  content: '\ec13';
}
.ti-layout-columns:before {
  content: '\ead4';
}
.ti-layout-distribute-horizontal:before {
  content: '\ead5';
}
.ti-layout-distribute-vertical:before {
  content: '\ead6';
}
.ti-layout-grid:before {
  content: '\edba';
}
.ti-layout-grid-add:before {
  content: '\edb9';
}
.ti-layout-kanban:before {
  content: '\ec3f';
}
.ti-layout-list:before {
  content: '\ec14';
}
.ti-layout-navbar:before {
  content: '\ead7';
}
.ti-layout-rows:before {
  content: '\ead8';
}
.ti-layout-sidebar:before {
  content: '\eada';
}
.ti-layout-sidebar-right:before {
  content: '\ead9';
}
.ti-leaf:before {
  content: '\ed4f';
}
.ti-lego:before {
  content: '\eadc';
}
.ti-lemon:before {
  content: '\ef10';
}
.ti-letter-a:before {
  content: '\ec50';
}
.ti-letter-b:before {
  content: '\ec51';
}
.ti-letter-c:before {
  content: '\ec52';
}
.ti-letter-case:before {
  content: '\eea5';
}
.ti-letter-case-lower:before {
  content: '\eea2';
}
.ti-letter-case-toggle:before {
  content: '\eea3';
}
.ti-letter-case-upper:before {
  content: '\eea4';
}
.ti-letter-d:before {
  content: '\ec53';
}
.ti-letter-e:before {
  content: '\ec54';
}
.ti-letter-f:before {
  content: '\ec55';
}
.ti-letter-g:before {
  content: '\ec56';
}
.ti-letter-h:before {
  content: '\ec57';
}
.ti-letter-i:before {
  content: '\ec58';
}
.ti-letter-j:before {
  content: '\ec59';
}
.ti-letter-k:before {
  content: '\ec5a';
}
.ti-letter-l:before {
  content: '\ec5b';
}
.ti-letter-m:before {
  content: '\ec5c';
}
.ti-letter-n:before {
  content: '\ec5d';
}
.ti-letter-o:before {
  content: '\ec5e';
}
.ti-letter-p:before {
  content: '\ec5f';
}
.ti-letter-q:before {
  content: '\ec60';
}
.ti-letter-r:before {
  content: '\ec61';
}
.ti-letter-s:before {
  content: '\ec62';
}
.ti-letter-spacing:before {
  content: '\eea6';
}
.ti-letter-t:before {
  content: '\ec63';
}
.ti-letter-u:before {
  content: '\ec64';
}
.ti-letter-v:before {
  content: '\ec65';
}
.ti-letter-w:before {
  content: '\ec66';
}
.ti-letter-x:before {
  content: '\ec67';
}
.ti-letter-y:before {
  content: '\ec68';
}
.ti-letter-z:before {
  content: '\ec69';
}
.ti-letters-case:before {
  content: '\ec6a';
}
.ti-license:before {
  content: '\ebc0';
}
.ti-lifebuoy:before {
  content: '\eadd';
}
.ti-line:before {
  content: '\ec40';
}
.ti-line-dashed:before {
  content: '\eea7';
}
.ti-line-dotted:before {
  content: '\eea8';
}
.ti-line-height:before {
  content: '\eb94';
}
.ti-link:before {
  content: '\eade';
}
.ti-list:before {
  content: '\eb6b';
}
.ti-list-check:before {
  content: '\eb6a';
}
.ti-list-numbers:before {
  content: '\ef11';
}
.ti-list-search:before {
  content: '\eea9';
}
.ti-live-photo:before {
  content: '\eadf';
}
.ti-live-view:before {
  content: '\ec6b';
}
.ti-loader:before {
  content: '\eca3';
}
.ti-loader-quarter:before {
  content: '\eca2';
}
.ti-location:before {
  content: '\eae0';
}
.ti-lock:before {
  content: '\eae2';
}
.ti-lock-access:before {
  content: '\eeaa';
}
.ti-lock-off:before {
  content: '\ed1e';
}
.ti-lock-open:before {
  content: '\eae1';
}
.ti-login:before {
  content: '\eba7';
}
.ti-logout:before {
  content: '\eba8';
}
.ti-macro:before {
  content: '\eeab';
}
.ti-magnet:before {
  content: '\eae3';
}
.ti-mail:before {
  content: '\eae5';
}
.ti-mail-forward:before {
  content: '\eeac';
}
.ti-mail-opened:before {
  content: '\eae4';
}
.ti-mailbox:before {
  content: '\eead';
}
.ti-man:before {
  content: '\eae6';
}
.ti-manual-gearbox:before {
  content: '\ed7b';
}
.ti-map:before {
  content: '\eae9';
}
.ti-map-2:before {
  content: '\eae7';
}
.ti-map-pin:before {
  content: '\eae8';
}
.ti-map-pin-off:before {
  content: '\ecf3';
}
.ti-map-pins:before {
  content: '\ed5e';
}
.ti-markdown:before {
  content: '\ec41';
}
.ti-marquee:before {
  content: '\ec77';
}
.ti-marquee-2:before {
  content: '\eeae';
}
.ti-mars:before {
  content: '\ec80';
}
.ti-mask:before {
  content: '\eeb0';
}
.ti-mask-off:before {
  content: '\eeaf';
}
.ti-massage:before {
  content: '\eeb1';
}
.ti-math:before {
  content: '\ebeb';
}
.ti-math-function:before {
  content: '\eeb2';
}
.ti-math-symbols:before {
  content: '\eeb3';
}
.ti-maximize:before {
  content: '\eaea';
}
.ti-meat:before {
  content: '\ef12';
}
.ti-medal:before {
  content: '\ec78';
}
.ti-medical-cross:before {
  content: '\ec2f';
}
.ti-menu:before {
  content: '\eaeb';
}
.ti-menu-2:before {
  content: '\ec42';
}
.ti-message:before {
  content: '\eaef';
}
.ti-message-2:before {
  content: '\eaec';
}
.ti-message-circle:before {
  content: '\eaed';
}
.ti-message-circle-2:before {
  content: '\ed3f';
}
.ti-message-circle-off:before {
  content: '\ed40';
}
.ti-message-dots:before {
  content: '\eaee';
}
.ti-message-off:before {
  content: '\ed41';
}
.ti-message-plus:before {
  content: '\ec9a';
}
.ti-message-report:before {
  content: '\ec9b';
}
.ti-messages:before {
  content: '\eb6c';
}
.ti-messages-off:before {
  content: '\ed42';
}
.ti-microphone:before {
  content: '\eaf0';
}
.ti-microphone-2:before {
  content: '\ef2c';
}
.ti-microphone-off:before {
  content: '\ed16';
}
.ti-milk:before {
  content: '\ef13';
}
.ti-minimize:before {
  content: '\eaf1';
}
.ti-minus:before {
  content: '\eaf2';
}
.ti-minus-vertical:before {
  content: '\eeb4';
}
.ti-mist:before {
  content: '\ec30';
}
.ti-mood-boy:before {
  content: '\ed2d';
}
.ti-mood-confuzed:before {
  content: '\eaf3';
}
.ti-mood-crazy-happy:before {
  content: '\ed90';
}
.ti-mood-cry:before {
  content: '\ecbb';
}
.ti-mood-empty:before {
  content: '\eeb5';
}
.ti-mood-happy:before {
  content: '\eaf4';
}
.ti-mood-kid:before {
  content: '\ec03';
}
.ti-mood-neutral:before {
  content: '\eaf5';
}
.ti-mood-sad:before {
  content: '\eaf6';
}
.ti-mood-smile:before {
  content: '\eaf7';
}
.ti-mood-suprised:before {
  content: '\ec04';
}
.ti-mood-tongue:before {
  content: '\eb95';
}
.ti-moon:before {
  content: '\eaf8';
}
.ti-moon-2:before {
  content: '\ece6';
}
.ti-moon-stars:before {
  content: '\ece7';
}
.ti-moped:before {
  content: '\ecbc';
}
.ti-motorbike:before {
  content: '\eeb6';
}
.ti-mouse:before {
  content: '\eaf9';
}
.ti-movie:before {
  content: '\eafa';
}
.ti-mug:before {
  content: '\eafb';
}
.ti-mushroom:before {
  content: '\ef14';
}
.ti-music:before {
  content: '\eafc';
}
.ti-new-section:before {
  content: '\ebc1';
}
.ti-news:before {
  content: '\eafd';
}
.ti-nfc:before {
  content: '\eeb7';
}
.ti-note:before {
  content: '\eb6d';
}
.ti-notebook:before {
  content: '\eb96';
}
.ti-notes:before {
  content: '\eb6e';
}
.ti-notification:before {
  content: '\eafe';
}
.ti-number-0:before {
  content: '\edf0';
}
.ti-number-1:before {
  content: '\edf1';
}
.ti-number-2:before {
  content: '\edf2';
}
.ti-number-3:before {
  content: '\edf3';
}
.ti-number-4:before {
  content: '\edf4';
}
.ti-number-5:before {
  content: '\edf5';
}
.ti-number-6:before {
  content: '\edf6';
}
.ti-number-7:before {
  content: '\edf7';
}
.ti-number-8:before {
  content: '\edf8';
}
.ti-number-9:before {
  content: '\edf9';
}
.ti-octagon:before {
  content: '\ecbd';
}
.ti-octagon-off:before {
  content: '\eeb8';
}
.ti-old:before {
  content: '\eeb9';
}
.ti-olympics:before {
  content: '\eeba';
}
.ti-omega:before {
  content: '\eb97';
}
.ti-outlet:before {
  content: '\ebd7';
}
.ti-overline:before {
  content: '\eebb';
}
.ti-package:before {
  content: '\eaff';
}
.ti-pacman:before {
  content: '\eebc';
}
.ti-page-break:before {
  content: '\ec81';
}
.ti-paint:before {
  content: '\eb00';
}
.ti-palette:before {
  content: '\eb01';
}
.ti-panorama-horizontal:before {
  content: '\ed33';
}
.ti-panorama-vertical:before {
  content: '\ed34';
}
.ti-paperclip:before {
  content: '\eb02';
}
.ti-parachute:before {
  content: '\ed7c';
}
.ti-parentheses:before {
  content: '\ebd8';
}
.ti-parking:before {
  content: '\eb03';
}
.ti-peace:before {
  content: '\ecbe';
}
.ti-pencil:before {
  content: '\eb04';
}
.ti-pennant:before {
  content: '\ed7d';
}
.ti-pepper:before {
  content: '\ef15';
}
.ti-percentage:before {
  content: '\ecf4';
}
.ti-perspective:before {
  content: '\eebd';
}
.ti-phone:before {
  content: '\eb09';
}
.ti-phone-call:before {
  content: '\eb05';
}
.ti-phone-calling:before {
  content: '\ec43';
}
.ti-phone-check:before {
  content: '\ec05';
}
.ti-phone-incoming:before {
  content: '\eb06';
}
.ti-phone-off:before {
  content: '\ecf5';
}
.ti-phone-outgoing:before {
  content: '\eb07';
}
.ti-phone-pause:before {
  content: '\eb08';
}
.ti-phone-plus:before {
  content: '\ec06';
}
.ti-phone-x:before {
  content: '\ec07';
}
.ti-photo:before {
  content: '\eb0a';
}
.ti-photo-off:before {
  content: '\ecf6';
}
.ti-physotherapist:before {
  content: '\eebe';
}
.ti-picture-in-picture:before {
  content: '\ed35';
}
.ti-picture-in-picture-off:before {
  content: '\ed43';
}
.ti-picture-in-picture-on:before {
  content: '\ed44';
}
.ti-pill:before {
  content: '\ec44';
}
.ti-pin:before {
  content: '\ec9c';
}
.ti-pinned:before {
  content: '\ed60';
}
.ti-pinned-off:before {
  content: '\ed5f';
}
.ti-pizza:before {
  content: '\edbb';
}
.ti-plane:before {
  content: '\eb6f';
}
.ti-plane-arrival:before {
  content: '\eb99';
}
.ti-plane-departure:before {
  content: '\eb9a';
}
.ti-planet:before {
  content: '\ec08';
}
.ti-plant:before {
  content: '\ed50';
}
.ti-plant-2:before {
  content: '\ed7e';
}
.ti-play-card:before {
  content: '\eebf';
}
.ti-player-pause:before {
  content: '\ed45';
}
.ti-player-play:before {
  content: '\ed46';
}
.ti-player-record:before {
  content: '\ed47';
}
.ti-player-skip-back:before {
  content: '\ed48';
}
.ti-player-skip-forward:before {
  content: '\ed49';
}
.ti-player-stop:before {
  content: '\ed4a';
}
.ti-player-track-next:before {
  content: '\ed4b';
}
.ti-player-track-prev:before {
  content: '\ed4c';
}
.ti-playlist:before {
  content: '\eec0';
}
.ti-plug:before {
  content: '\ebd9';
}
.ti-plus:before {
  content: '\eb0b';
}
.ti-point:before {
  content: '\eb0c';
}
.ti-pokeball:before {
  content: '\eec1';
}
.ti-polaroid:before {
  content: '\eec2';
}
.ti-pool:before {
  content: '\ed91';
}
.ti-power:before {
  content: '\eb0d';
}
.ti-pray:before {
  content: '\ecbf';
}
.ti-presentation:before {
  content: '\eb70';
}
.ti-presentation-analytics:before {
  content: '\eec3';
}
.ti-printer:before {
  content: '\eb0e';
}
.ti-prompt:before {
  content: '\eb0f';
}
.ti-propeller:before {
  content: '\eec4';
}
.ti-puzzle:before {
  content: '\eb10';
}
.ti-pyramid:before {
  content: '\eec5';
}
.ti-qrcode:before {
  content: '\eb11';
}
.ti-question-mark:before {
  content: '\ec9d';
}
.ti-radio:before {
  content: '\ef2d';
}
.ti-radioactive:before {
  content: '\ecc0';
}
.ti-radius-bottom-left:before {
  content: '\eec6';
}
.ti-radius-bottom-right:before {
  content: '\eec7';
}
.ti-radius-top-left:before {
  content: '\eec8';
}
.ti-radius-top-right:before {
  content: '\eec9';
}
.ti-rainbow:before {
  content: '\edbc';
}
.ti-receipt:before {
  content: '\edfd';
}
.ti-receipt-2:before {
  content: '\edfa';
}
.ti-receipt-off:before {
  content: '\edfb';
}
.ti-receipt-refund:before {
  content: '\edfc';
}
.ti-receipt-tax:before {
  content: '\edbd';
}
.ti-recharging:before {
  content: '\eeca';
}
.ti-record-mail:before {
  content: '\eb12';
}
.ti-rectangle:before {
  content: '\ed37';
}
.ti-rectangle-vertical:before {
  content: '\ed36';
}
.ti-recycle:before {
  content: '\eb9b';
}
.ti-refresh:before {
  content: '\eb13';
}
.ti-refresh-alert:before {
  content: '\ed57';
}
.ti-registered:before {
  content: '\eb14';
}
.ti-relation-many-to-many:before {
  content: '\ed7f';
}
.ti-relation-one-to-many:before {
  content: '\ed80';
}
.ti-relation-one-to-one:before {
  content: '\ed81';
}
.ti-repeat:before {
  content: '\eb72';
}
.ti-repeat-once:before {
  content: '\eb71';
}
.ti-replace:before {
  content: '\ebc7';
}
.ti-report:before {
  content: '\eece';
}
.ti-report-analytics:before {
  content: '\eecb';
}
.ti-report-medical:before {
  content: '\eecc';
}
.ti-report-money:before {
  content: '\eecd';
}
.ti-resize:before {
  content: '\eecf';
}
.ti-ripple:before {
  content: '\ed82';
}
.ti-road-sign:before {
  content: '\ecdd';
}
.ti-rocket:before {
  content: '\ec45';
}
.ti-rotate:before {
  content: '\eb16';
}
.ti-rotate-2:before {
  content: '\ebb4';
}
.ti-rotate-clockwise:before {
  content: '\eb15';
}
.ti-rotate-clockwise-2:before {
  content: '\ebb5';
}
.ti-rotate-rectangle:before {
  content: '\ec15';
}
.ti-route:before {
  content: '\eb17';
}
.ti-router:before {
  content: '\eb18';
}
.ti-row-insert-bottom:before {
  content: '\eed0';
}
.ti-row-insert-top:before {
  content: '\eed1';
}
.ti-rss:before {
  content: '\eb19';
}
.ti-ruler:before {
  content: '\eb1a';
}
.ti-ruler-2:before {
  content: '\eed2';
}
.ti-run:before {
  content: '\ec82';
}
.ti-sailboat:before {
  content: '\ec83';
}
.ti-salt:before {
  content: '\ef16';
}
.ti-satellite:before {
  content: '\eed3';
}
.ti-sausage:before {
  content: '\ef17';
}
.ti-scale:before {
  content: '\ebc2';
}
.ti-scan:before {
  content: '\ebc8';
}
.ti-school:before {
  content: '\ecf7';
}
.ti-scissors:before {
  content: '\eb1b';
}
.ti-scooter:before {
  content: '\ec6c';
}
.ti-scooter-electric:before {
  content: '\ecc1';
}
.ti-screen-share:before {
  content: '\ed18';
}
.ti-screen-share-off:before {
  content: '\ed17';
}
.ti-scuba-mask:before {
  content: '\eed4';
}
.ti-search:before {
  content: '\eb1c';
}
.ti-section:before {
  content: '\eed5';
}
.ti-seeding:before {
  content: '\ed51';
}
.ti-select:before {
  content: '\ec9e';
}
.ti-selector:before {
  content: '\eb1d';
}
.ti-send:before {
  content: '\eb1e';
}
.ti-separator:before {
  content: '\ebda';
}
.ti-separator-horizontal:before {
  content: '\ec79';
}
.ti-separator-vertical:before {
  content: '\ec7a';
}
.ti-server:before {
  content: '\eb1f';
}
.ti-servicemark:before {
  content: '\ec09';
}
.ti-settings:before {
  content: '\eb20';
}
.ti-settings-automation:before {
  content: '\eed6';
}
.ti-shadow:before {
  content: '\eed8';
}
.ti-shadow-off:before {
  content: '\eed7';
}
.ti-shape:before {
  content: '\eb9c';
}
.ti-shape-2:before {
  content: '\eed9';
}
.ti-shape-3:before {
  content: '\eeda';
}
.ti-share:before {
  content: '\eb21';
}
.ti-shield:before {
  content: '\eb24';
}
.ti-shield-check:before {
  content: '\eb22';
}
.ti-shield-lock:before {
  content: '\ed58';
}
.ti-shield-off:before {
  content: '\ecf8';
}
.ti-shield-x:before {
  content: '\eb23';
}
.ti-ship:before {
  content: '\ec84';
}
.ti-shirt:before {
  content: '\ec0a';
}
.ti-shopping-cart:before {
  content: '\eb25';
}
.ti-shopping-cart-discount:before {
  content: '\eedb';
}
.ti-shopping-cart-off:before {
  content: '\eedc';
}
.ti-shopping-cart-plus:before {
  content: '\eedd';
}
.ti-shopping-cart-x:before {
  content: '\eede';
}
.ti-shredder:before {
  content: '\eedf';
}
.ti-signature:before {
  content: '\eee0';
}
.ti-sitemap:before {
  content: '\eb9d';
}
.ti-skateboard:before {
  content: '\ecc2';
}
.ti-slice:before {
  content: '\ebdb';
}
.ti-slideshow:before {
  content: '\ebc9';
}
.ti-smart-home:before {
  content: '\ecde';
}
.ti-smoking:before {
  content: '\ecc4';
}
.ti-smoking-no:before {
  content: '\ecc3';
}
.ti-snowflake:before {
  content: '\ec0b';
}
.ti-soccer-field:before {
  content: '\ed92';
}
.ti-social:before {
  content: '\ebec';
}
.ti-sock:before {
  content: '\eee1';
}
.ti-sort-ascending:before {
  content: '\eb26';
}
.ti-sort-ascending-2:before {
  content: '\eee2';
}
.ti-sort-ascending-letters:before {
  content: '\ef18';
}
.ti-sort-ascending-numbers:before {
  content: '\ef19';
}
.ti-sort-descending:before {
  content: '\eb27';
}
.ti-sort-descending-2:before {
  content: '\eee3';
}
.ti-sort-descending-letters:before {
  content: '\ef1a';
}
.ti-sort-descending-numbers:before {
  content: '\ef1b';
}
.ti-soup:before {
  content: '\ef2e';
}
.ti-space:before {
  content: '\ec0c';
}
.ti-speakerphone:before {
  content: '\ed61';
}
.ti-speedboat:before {
  content: '\ed93';
}
.ti-sport-billard:before {
  content: '\eee4';
}
.ti-square:before {
  content: '\eb2c';
}
.ti-square-0:before {
  content: '\eee5';
}
.ti-square-1:before {
  content: '\eee6';
}
.ti-square-2:before {
  content: '\eee7';
}
.ti-square-3:before {
  content: '\eee8';
}
.ti-square-4:before {
  content: '\eee9';
}
.ti-square-5:before {
  content: '\eeea';
}
.ti-square-6:before {
  content: '\eeeb';
}
.ti-square-7:before {
  content: '\eeec';
}
.ti-square-8:before {
  content: '\eeed';
}
.ti-square-9:before {
  content: '\eeee';
}
.ti-square-check:before {
  content: '\eb28';
}
.ti-square-dot:before {
  content: '\ed59';
}
.ti-square-forbid:before {
  content: '\ed5b';
}
.ti-square-forbid-2:before {
  content: '\ed5a';
}
.ti-square-minus:before {
  content: '\eb29';
}
.ti-square-off:before {
  content: '\eeef';
}
.ti-square-plus:before {
  content: '\eb2a';
}
.ti-square-root:before {
  content: '\eef1';
}
.ti-square-root-2:before {
  content: '\eef0';
}
.ti-square-rotated:before {
  content: '\ecdf';
}
.ti-square-rotated-off:before {
  content: '\eef2';
}
.ti-square-toggle:before {
  content: '\eef4';
}
.ti-square-toggle-horizontal:before {
  content: '\eef3';
}
.ti-square-x:before {
  content: '\eb2b';
}
.ti-squares-diagonal:before {
  content: '\eef5';
}
.ti-squares-filled:before {
  content: '\eef6';
}
.ti-stack:before {
  content: '\eb2d';
}
.ti-stack-2:before {
  content: '\eef7';
}
.ti-stairs:before {
  content: '\eca6';
}
.ti-stairs-down:before {
  content: '\eca4';
}
.ti-stairs-up:before {
  content: '\eca5';
}
.ti-star:before {
  content: '\eb2e';
}
.ti-star-half:before {
  content: '\ed19';
}
.ti-star-off:before {
  content: '\ed62';
}
.ti-stars:before {
  content: '\ed38';
}
.ti-steering-wheel:before {
  content: '\ec7b';
}
.ti-step-into:before {
  content: '\ece0';
}
.ti-step-out:before {
  content: '\ece1';
}
.ti-stethoscope:before {
  content: '\edbe';
}
.ti-sticker:before {
  content: '\eb2f';
}
.ti-strikethrough:before {
  content: '\eb9e';
}
.ti-submarine:before {
  content: '\ed94';
}
.ti-subscript:before {
  content: '\eb9f';
}
.ti-subtask:before {
  content: '\ec9f';
}
.ti-sum:before {
  content: '\eb73';
}
.ti-sun:before {
  content: '\eb30';
}
.ti-sun-off:before {
  content: '\ed63';
}
.ti-sunrise:before {
  content: '\ef1c';
}
.ti-sunset:before {
  content: '\ec31';
}
.ti-superscript:before {
  content: '\eba0';
}
.ti-swimming:before {
  content: '\ec92';
}
.ti-switch:before {
  content: '\eb33';
}
.ti-switch-2:before {
  content: '\edbf';
}
.ti-switch-3:before {
  content: '\edc0';
}
.ti-switch-horizontal:before {
  content: '\eb31';
}
.ti-switch-vertical:before {
  content: '\eb32';
}
.ti-table:before {
  content: '\eba1';
}
.ti-table-export:before {
  content: '\eef8';
}
.ti-table-import:before {
  content: '\eef9';
}
.ti-table-off:before {
  content: '\eefa';
}
.ti-tag:before {
  content: '\eb34';
}
.ti-tallymark-1:before {
  content: '\ec46';
}
.ti-tallymark-2:before {
  content: '\ec47';
}
.ti-tallymark-3:before {
  content: '\ec48';
}
.ti-tallymark-4:before {
  content: '\ec49';
}
.ti-tallymarks:before {
  content: '\ec4a';
}
.ti-tank:before {
  content: '\ed95';
}
.ti-target:before {
  content: '\eb35';
}
.ti-temperature:before {
  content: '\eb38';
}
.ti-temperature-celsius:before {
  content: '\eb36';
}
.ti-temperature-fahrenheit:before {
  content: '\eb37';
}
.ti-temperature-minus:before {
  content: '\ebed';
}
.ti-temperature-plus:before {
  content: '\ebee';
}
.ti-template:before {
  content: '\eb39';
}
.ti-tent:before {
  content: '\eefb';
}
.ti-terminal:before {
  content: '\ebdc';
}
.ti-terminal-2:before {
  content: '\ebef';
}
.ti-test-pipe:before {
  content: '\eb3a';
}
.ti-text-direction-ltr:before {
  content: '\eefc';
}
.ti-text-direction-rtl:before {
  content: '\eefd';
}
.ti-text-wrap:before {
  content: '\ebdd';
}
.ti-text-wrap-disabled:before {
  content: '\eca7';
}
.ti-thumb-down:before {
  content: '\eb3b';
}
.ti-thumb-up:before {
  content: '\eb3c';
}
.ti-ticket:before {
  content: '\eb3d';
}
.ti-tilt-shift:before {
  content: '\eefe';
}
.ti-tir:before {
  content: '\ebf0';
}
.ti-toggle-left:before {
  content: '\eb3e';
}
.ti-toggle-right:before {
  content: '\eb3f';
}
.ti-tool:before {
  content: '\eb40';
}
.ti-tools:before {
  content: '\ebca';
}
.ti-tools-kitchen:before {
  content: '\ed64';
}
.ti-tools-kitchen-2:before {
  content: '\eeff';
}
.ti-tornado:before {
  content: '\ece2';
}
.ti-tournament:before {
  content: '\ecd0';
}
.ti-track:before {
  content: '\ef00';
}
.ti-tractor:before {
  content: '\ec0d';
}
.ti-trademark:before {
  content: '\ec0e';
}
.ti-traffic-cone:before {
  content: '\ec0f';
}
.ti-traffic-lights:before {
  content: '\ed39';
}
.ti-train:before {
  content: '\ed96';
}
.ti-transfer-in:before {
  content: '\ef2f';
}
.ti-transfer-out:before {
  content: '\ef30';
}
.ti-trash:before {
  content: '\eb41';
}
.ti-trash-off:before {
  content: '\ed65';
}
.ti-tree:before {
  content: '\ef01';
}
.ti-trees:before {
  content: '\ec10';
}
.ti-trending-down:before {
  content: '\eb42';
}
.ti-trending-down-2:before {
  content: '\edc1';
}
.ti-trending-down-3:before {
  content: '\edc2';
}
.ti-trending-up:before {
  content: '\eb43';
}
.ti-trending-up-2:before {
  content: '\edc3';
}
.ti-trending-up-3:before {
  content: '\edc4';
}
.ti-triangle:before {
  content: '\eb44';
}
.ti-triangle-off:before {
  content: '\ef02';
}
.ti-triangle-square-circle:before {
  content: '\ece8';
}
.ti-trident:before {
  content: '\ecc5';
}
.ti-trophy:before {
  content: '\eb45';
}
.ti-truck:before {
  content: '\ebc4';
}
.ti-truck-delivery:before {
  content: '\ec4b';
}
.ti-truck-off:before {
  content: '\ef03';
}
.ti-truck-return:before {
  content: '\ec4c';
}
.ti-typography:before {
  content: '\ebc5';
}
.ti-umbrella:before {
  content: '\ebf1';
}
.ti-underline:before {
  content: '\eba2';
}
.ti-unlink:before {
  content: '\eb46';
}
.ti-upload:before {
  content: '\eb47';
}
.ti-urgent:before {
  content: '\eb48';
}
.ti-user:before {
  content: '\eb4d';
}
.ti-user-check:before {
  content: '\eb49';
}
.ti-user-exclamation:before {
  content: '\ec12';
}
.ti-user-minus:before {
  content: '\eb4a';
}
.ti-user-off:before {
  content: '\ecf9';
}
.ti-user-plus:before {
  content: '\eb4b';
}
.ti-user-x:before {
  content: '\eb4c';
}
.ti-users:before {
  content: '\ebf2';
}
.ti-vaccine:before {
  content: '\ef04';
}
.ti-variable:before {
  content: '\ef05';
}
.ti-vector:before {
  content: '\eca9';
}
.ti-vector-beizer-2:before {
  content: '\ed3a';
}
.ti-vector-bezier:before {
  content: '\ef1d';
}
.ti-vector-triangle:before {
  content: '\eca8';
}
.ti-venus:before {
  content: '\ec86';
}
.ti-versions:before {
  content: '\ed52';
}
.ti-video:before {
  content: '\ed22';
}
.ti-video-minus:before {
  content: '\ed1f';
}
.ti-video-off:before {
  content: '\ed20';
}
.ti-video-plus:before {
  content: '\ed21';
}
.ti-view-360:before {
  content: '\ed84';
}
.ti-viewfinder:before {
  content: '\eb4e';
}
.ti-viewport-narrow:before {
  content: '\ebf3';
}
.ti-viewport-wide:before {
  content: '\ebf4';
}
.ti-virus:before {
  content: '\eb74';
}
.ti-virus-off:before {
  content: '\ed66';
}
.ti-virus-search:before {
  content: '\ed67';
}
.ti-vocabulary:before {
  content: '\ef1e';
}
.ti-volume:before {
  content: '\eb51';
}
.ti-volume-2:before {
  content: '\eb4f';
}
.ti-volume-3:before {
  content: '\eb50';
}
.ti-walk:before {
  content: '\ec87';
}
.ti-wallet:before {
  content: '\eb75';
}
.ti-wand:before {
  content: '\ebcb';
}
.ti-wave-saw-tool:before {
  content: '\ecd3';
}
.ti-wave-sine:before {
  content: '\ecd4';
}
.ti-wave-square:before {
  content: '\ecd5';
}
.ti-wifi:before {
  content: '\eb52';
}
.ti-wifi-0:before {
  content: '\eba3';
}
.ti-wifi-1:before {
  content: '\eba4';
}
.ti-wifi-2:before {
  content: '\eba5';
}
.ti-wifi-off:before {
  content: '\ecfa';
}
.ti-wind:before {
  content: '\ec34';
}
.ti-windmill:before {
  content: '\ed85';
}
.ti-window:before {
  content: '\ef06';
}
.ti-wiper:before {
  content: '\ecab';
}
.ti-wiper-wash:before {
  content: '\ecaa';
}
.ti-woman:before {
  content: '\eb53';
}
.ti-world:before {
  content: '\eb54';
}
.ti-world-latitude:before {
  content: '\ed2e';
}
.ti-world-longitude:before {
  content: '\ed2f';
}
.ti-wrecking-ball:before {
  content: '\ed97';
}
.ti-writing:before {
  content: '\ef08';
}
.ti-writing-sign:before {
  content: '\ef07';
}
.ti-x:before {
  content: '\eb55';
}
.ti-yin-yang:before {
  content: '\ec35';
}
.ti-zodiac-aquarius:before {
  content: '\ecac';
}
.ti-zodiac-aries:before {
  content: '\ecad';
}
.ti-zodiac-cancer:before {
  content: '\ecae';
}
.ti-zodiac-capricorn:before {
  content: '\ecaf';
}
.ti-zodiac-gemini:before {
  content: '\ecb0';
}
.ti-zodiac-leo:before {
  content: '\ecb1';
}
.ti-zodiac-libra:before {
  content: '\ecb2';
}
.ti-zodiac-pisces:before {
  content: '\ecb3';
}
.ti-zodiac-sagittarius:before {
  content: '\ecb4';
}
.ti-zodiac-scorpio:before {
  content: '\ecb5';
}
.ti-zodiac-taurus:before {
  content: '\ecb6';
}
.ti-zodiac-virgo:before {
  content: '\ecb7';
}
.ti-zoom-cancel:before {
  content: '\ec4d';
}
.ti-zoom-check:before {
  content: '\ef09';
}
.ti-zoom-in:before {
  content: '\eb56';
}
.ti-zoom-money:before {
  content: '\ef0a';
}
.ti-zoom-out:before {
  content: '\eb57';
}
.ti-zoom-question:before {
  content: '\edeb';
}
